import * as React from 'react'
import { useLocation } from 'react-router'
import { Navigation } from '@components'
import type { PaymentStatus } from '@payment/api'
import CaptureWallet from '@payment/views/Capture/CaptureWallet.tsx'
import walletApi from '@wallet/api'
import type { CaptureLocationState } from './Capture.types'
import { useCheckPaymentStatus } from './Capture.hooks'
import CallbackWaiting from './CallbackWaiting'
import CaptureFooter from './CaptureFooter'
import CapturePrice from './CapturePrice'

const Capture: React.FC = () => {
    const location = useLocation().state as CaptureLocationState

    const [pointUsage, setPointUsage] = React.useState<boolean>(false)
    const [status, setStatus] = React.useState<PaymentStatus>(
        location.payment.status,
    )
    const [isCaptureAvailable, setCaptureAvailable] =
        React.useState<boolean>(false)

    const walletDetailsQuery = walletApi.useWalletDetailsQuery(
        { wallet_id: location.wallet.id },
        { refetchOnMountOrArgChange: true },
    )

    const wallet = React.useMemo(
        () => walletDetailsQuery.data?.data,
        [walletDetailsQuery.data],
    )

    useCheckPaymentStatus(location.payment.payment_id, status, setStatus)

    const payment = React.useMemo(() => location.payment, [location.payment])

    const hasDiscount = React.useMemo(() => {
        return payment.final_total !== payment.discounted_total_amount
    }, [payment.discounted_total_amount, payment.final_total])

    if (status === 'callback_waiting') return <CallbackWaiting />

    if (!wallet) return null

    return (
        <div>
            <Navigation
                className="bg-semantic-background-secondary"
                showBackButton={false}
                LayoutRightComponent={
                    <div className="flex w-full items-center justify-end gap-4">
                        <h4 className="text-display-sm text-semantic-content-ink">
                            {wallet.company.name}
                        </h4>
                        <img
                            src={wallet.company.logo}
                            alt={wallet.company.name}
                            className="h-8 w-8 rounded-full object-cover"
                        />
                    </div>
                }
            />
            <CapturePrice
                amount={payment.final_total}
                discountedAmount={payment.discounted_total_amount}
                discountPercent={payment.discount_percent}
                currency={wallet.company.currency}
                hasDiscount={hasDiscount}
            />
            <CaptureWallet
                wallet={wallet}
                payment={payment}
                paymentId={payment.payment_id}
                status={status}
                onChangePointUsage={setPointUsage}
                onCaptureAvailable={setCaptureAvailable}
            />
            <CaptureFooter
                payment={payment}
                pointUsage={pointUsage}
                captureAvailable={isCaptureAvailable}
                onChangeStatus={setStatus}
            />
        </div>
    )
}
export default Capture
