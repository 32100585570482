import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { SectionBar } from '@components'
import { AccountActivityList } from '@wallet/components'
import type { HistorySectionProps } from './WalletDetail.types'

const HistorySection: React.FC<HistorySectionProps> = ({ walletId }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <div className="container mb-8 space-y-6">
            <AccountActivityList
                config={{ wallet_id: walletId }}
                limit={5}
                ListHeaderComponent={
                    <SectionBar
                        title={t('@wallet.WalletDetail.wallet_history_title')}
                        description={t(
                            '@wallet.WalletDetail.wallet_history_subtitle',
                        )}
                        LayoutRightComponent
                        LayoutRightComponentProps={{
                            children: t(
                                '@wallet.WalletDetail.wallet_history_all',
                            ),
                        }}
                        NavigateLink={() =>
                            navigate('/account-activities', {
                                state: { walletId },
                            })
                        }
                    />
                }
            />
        </div>
    )
}

export default HistorySection
