import * as React from 'react'
import { useNavigate } from 'react-router'
import { Button, Icon } from '@components'
import type { NavigationProps } from './Navigation.types'
import { cn } from '@core/utils'

const Navigation: React.FC<NavigationProps> = ({
    title,
    className,
    showBackButton = true,
    LayoutRightComponent,
    ...props
}) => {
    const navigate = useNavigate()

    return (
        <header
            className={cn(
                'relative flex h-[50px] items-center justify-between border-b border-semantic-background-tertiary px-4',
                className,
            )}
            {...props}>
            {showBackButton && (
                <Button
                    variant="ghost-on-light"
                    size="tiny"
                    rounded="pill"
                    onClick={() => navigate(-1)}>
                    <Icon name="arrow-left-bold" size={20} />
                </Button>
            )}
            {title && (
                <h3 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-display-xs text-semantic-content-ink">
                    {title}
                </h3>
            )}
            {LayoutRightComponent}
        </header>
    )
}
export default Navigation
