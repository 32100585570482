/* eslint-disable  */
import Echo from 'laravel-echo'
import store from '@core/store'
import { Environment } from '@core/constants'
import Pusher from 'pusher-js'

export let echo: Echo | null = null

const getInstance = () => echo

const getState = () => echo?.connector.pusher.connection.state

const disconnect = (callback?: () => void) => {
    echo?.leaveAllChannels()
    echo?.disconnect()
    callback?.()
}

const connect = async (callback?: (socket: Echo) => void) => {
    disconnect()

    const accessToken = localStorage.getItem('user.access_token')

    if (!accessToken) return

    const pusherEnv = store.getState().baseSlice.pusherEnv

    if (!pusherEnv.key || !pusherEnv.host || !pusherEnv.port) return

    echo = new Echo({
        Pusher: Pusher,
        broadcaster: 'pusher',
        key: pusherEnv.key,
        wsHost: pusherEnv.host,
        wsPort: pusherEnv.port,
        wssPort: pusherEnv.port,
        cluster: 'eu',
        forceTLS: false,
        encrypted: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        authEndpoint: `${Environment.BASE_URL}broadcasting/auth`,
        bearerToken: accessToken,
    })

    echo?.connector.pusher.connection.bind('state_change', (states: any) => {
        if (
            states.current === 'unavailable' ||
            states.current === 'failed' ||
            states.current === 'disconnected'
        ) {
            connect()
        }
    })

    return callback?.(echo)
}

export default {
    getInstance: getInstance,
    getState: getState,
    connect: connect,
    disconnect: disconnect,
}
