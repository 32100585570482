import * as React from 'react'
import { useLocation } from 'react-router'
import { InfoSheet } from '@components'
import Logo from '@core/assets/logo_dark.svg'
import type { NotFoundProps } from './NotFound.types'

const NotFound: React.FC<NotFoundProps> = ({
    title,
    description,
    errorMessages,
    actions,
}) => {
    const state = useLocation().state

    return (
        <div className="flex h-screen flex-col px-8 py-9">
            <img src={Logo} alt="404" className="h-fit w-fit" />
            <InfoSheet
                icon="info"
                title={state?.data?.message || title}
                description={state?.data ? undefined : description}
                errorMessages={errorMessages}
                actions={state?.data ? undefined : actions}
                className="flex-grow"
            />
        </div>
    )
}

export default NotFound
