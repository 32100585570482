import { createBrowserRouter, Navigate, type RouteObject } from 'react-router'
import { Auth } from '@core/routes/Layout'
import { CampaignRouter } from '@campaign/router'
import { ErrorRouter } from '@common/router'
import { GuestRouter } from '@guest/router'
import { DepositRouter } from '@deposit/router'
import { PaymentRouter } from '@payment/router'
import { WalletRouter } from '@wallet/router'

const routes: RouteObject[] = [
    {
        children: [...GuestRouter], // This is the Guest component
    },
    {
        element: <Auth />,
        children: [
            ...CampaignRouter,
            ...DepositRouter,
            ...WalletRouter,
            ...PaymentRouter,
        ], // This is the Auth component
    },
    {
        children: [...ErrorRouter],
    },
    {
        path: '*',
        element: <Navigate to="/404" replace />,
    },
]

export default routes

export const router = createBrowserRouter(routes)
