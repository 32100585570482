import * as React from 'react'
import { map } from 'lodash'
import { Button, Icon } from '@components'
import type { InfoSheetProps } from './InfoSheet.types'
import { cn, useTheme } from '@core/utils'

const InfoSheet: React.FC<InfoSheetProps> = ({
    icon = 'info',
    title,
    description,
    errorMessages,
    actions,
    className,
    ...props
}) => {
    const theme = useTheme().colors

    return (
        <div
            className={cn(
                'flex flex-col items-center justify-center space-y-14 text-center',
                className,
            )}
            {...props}>
            <div className="flex h-[160px] w-[160px] items-center justify-center rounded-full bg-semantic-background-primary">
                <Icon
                    name={icon}
                    size={56}
                    color={theme.semantic.content.inkLight}
                />
            </div>

            <div className="space-y-12">
                <div className="space-y-12">
                    <div className="space-y-6">
                        <h2 className="text-display-hg text-semantic-content-ink">
                            {title}
                        </h2>
                        {description && (
                            <p className="text-body-lg-r text-semantic-content-inkLight">
                                {description}
                            </p>
                        )}
                    </div>
                    {errorMessages && (
                        <span className="inline-flex text-overline-md text-semantic-content-inkLight">
                            {errorMessages}
                        </span>
                    )}
                </div>
                {actions && (
                    <div className="flex flex-col items-center justify-center gap-4">
                        {map(actions, (action, index) => (
                            <Button
                                key={index}
                                className="min-w-[278px]"
                                {...action}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}
export default InfoSheet
