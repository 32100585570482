import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Button, ToolBar } from '@components'
import { useAppSelector } from '@core/store'
import type { CampaignDetailFooterProps } from './CampaignDetail.types'

const CampaignDetailFooter: React.FC<CampaignDetailFooterProps> = ({
    walletId,
}) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const company = useAppSelector(state => state.baseSlice.company)

    const description = React.useMemo(() => {
        return walletId
            ? t('@campaign.CampaignDetail.footer_deposit_description')
            : t('@campaign.CampaignDetail.footer_add_wallet_description')
    }, [walletId, t])

    const buttonTitle = React.useMemo(() => {
        return walletId
            ? t('@campaign.CampaignDetail.footer_deposit_button')
            : t('@campaign.CampaignDetail.footer_add_wallet_button')
    }, [walletId, t])

    const navigateByType = React.useCallback(() => {
        if (walletId)
            return navigate('/topup', { state: { walletId: walletId } })
    }, [navigate, walletId])

    return (
        <ToolBar className="sticky bottom-0 left-0 w-full">
            <ToolBar.Left>
                <p className="text-body-md-r text-semantic-content-inkMedium">
                    {description}
                </p>
            </ToolBar.Left>
            <ToolBar.Right>
                <Button
                    className="px-8"
                    children={buttonTitle}
                    disabled={company.is_wallet_frozen}
                    onClick={navigateByType}
                />
            </ToolBar.Right>
        </ToolBar>
    )
}
export default CampaignDetailFooter
