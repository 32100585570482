import { cva } from 'class-variance-authority'

export const moduleItemButtonStyles = cva(
    [
        'flex',
        'flex-col',
        'gap-6',
        'p-3',
        'rounded-[13px]',
        'focus:ring-2',
        'text-left text-label-md [word-spacing:100vw]',
        'animate-in fade-in duration-300',
        'disabled:opacity-80',
    ],
    {
        variants: {
            variant: {
                primary: [
                    'bg-semantic-content-inkInverse',
                    '!text-semantic-content-ink fill-semantic-background-ink',
                    'focus:ring-tint-white-40',
                ],
                secondary: [
                    'bg-tint-white-10',
                    'border-[1px]',
                    'border-tint-white-10',
                    '!text-semantic-content-inkInverse fill-semantic-content-inkInverse',
                    'focus:ring-tint-white-10',
                ],
            },
        },
        defaultVariants: {
            variant: 'primary',
        },
    },
)
