import { createSlice } from '@reduxjs/toolkit'
import type { AppSettings, Company } from '@core/api'
import baseApi, { type User } from '@core/api'
import type { BaseState } from './BaseSlice.types'

const initialState: BaseState = {
    platform: null,
    me: {} as User,
    pusherEnv: {
        host: null,
        key: null,
        port: null,
    },
    company: {} as Company,
    appSettings: {} as AppSettings,
}

const BaseSlice = createSlice({
    name: 'baseSlice',
    initialState: initialState,
    reducers: {
        setPlatform: (state, action) => {
            state.platform = action.payload
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            baseApi.endpoints.me.matchFulfilled,
            (state, action) => {
                state.me = action.payload.data
            },
        )
        builder.addMatcher(
            baseApi.endpoints.appInfo.matchFulfilled,
            (state, action) => {
                state.pusherEnv = action.payload.data.pusher
            },
        )
        builder.addMatcher(
            baseApi.endpoints.initialize.matchFulfilled,
            (state, action) => {
                state.company = action.payload.data.company
            },
        )
        builder.addMatcher(
            baseApi.endpoints.initialize.matchFulfilled,
            (state, action) => {
                state.appSettings = action.payload.data.app_settings
            },
        )
    },
})

export const { setPlatform } = BaseSlice.actions

export default BaseSlice.reducer
