import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'
import type { CurrencyCode } from '@macellan/formatter'
import type { IconsNameType } from '@components'
import { useAppSelector } from '@core/store'
import type { WalletModuleName } from '@wallet/api'
import { CouponApply } from '@wallet/views'

export interface WalletModuleItem {
    type: 'component' | 'button'
    component?: React.ReactNode
    title?: string
    icon?: IconsNameType
    // eslint-disable-next-line no-unused-vars
    callback?: (params: never) => void
    state?: boolean
}

export interface WalletModuleParams {
    walletId: number
    currency: CurrencyCode
    index?: number
}

const useWalletModule = (
    name: WalletModuleName,
    params: WalletModuleParams,
) => {
    const t = useTranslation().t
    const navigate = useNavigate()

    const platform = useAppSelector(state => state.baseSlice.platform)

    return React.useMemo<WalletModuleItem | undefined>(() => {
        if (name === 'payment' && isEqual(platform, 'mobile_waas'))
            return {
                type: 'button',
                title: t('@wallet.WalletModules.payment'),
                icon: 'wallet',
                callback: () => navigate('/payment-qr'),
            }

        if (name === 'coupon') {
            return {
                type: 'component',
                component: (
                    <CouponApply
                        currency={params.currency}
                        walletId={params.walletId}
                    />
                ),
            }
        }

        if (name === 'deposit') {
            return {
                type: 'button',
                title: t('@wallet.WalletModules.deposit'),
                icon: 'plus-bold',
                callback: () =>
                    navigate('/topup', {
                        state: { walletId: params.walletId },
                    }),
            }
        }
    }, [name, platform, t, navigate, params.currency, params.walletId])
}

export default useWalletModule
