import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { EmptySheet, SectionBar, Tabs } from '@components'
import { CampaignSegmentedList } from '@campaign/components'
import type { CampaignsHeaderProps } from './Campaigns.types'
import { cn } from '@core/utils'

const CampaignsHeader: React.FC<CampaignsHeaderProps> = ({
    campaignTabItems,
    activeTab,
    setActiveTab,
    itemsCount,
    loading,
    className,
    children,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <React.Fragment>
            <SectionBar
                title={t('@campaign.Campaigns.route_header_title')}
                description={t('@campaign.Campaigns.route_header_description')}
                LayoutBackButton={{
                    onClick: () => navigate(-1),
                }}
                className="container sticky top-0 z-50 bg-semantic-content-inkInverse py-7 lg:static lg:z-auto"
            />
            <div className={cn('space-y-10', className)}>
                <Tabs defaultValue={activeTab} className="w-full">
                    <Tabs.List className="w-full">
                        <div className="container flex w-full gap-8">
                            {map(campaignTabItems, (tab, key) => (
                                <Tabs.Trigger
                                    key={key}
                                    className="border-b-[3px]"
                                    value={tab.key}
                                    onClick={() => setActiveTab(tab.key)}>
                                    {tab.label}
                                </Tabs.Trigger>
                            ))}
                        </div>
                    </Tabs.List>

                    {loading && (
                        <CampaignSegmentedList.Loader
                            className="container my-8"
                            tabsVisible={false}
                            limit={8}
                        />
                    )}

                    {map(campaignTabItems, (tab, key) => {
                        if (loading) return null

                        if (!itemsCount)
                            return (
                                <EmptySheet
                                    icon="info"
                                    title={t(
                                        '@campaign.Campaigns.empty_sheet_title',
                                    )}
                                    description={t(
                                        '@campaign.Campaigns.empty_sheet_description',
                                    )}
                                    className="max-w-full pt-8"
                                />
                            )

                        return (
                            <Tabs.Content
                                key={key}
                                value={tab.key}
                                className="container my-8">
                                <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                                    {children}
                                </div>
                            </Tabs.Content>
                        )
                    })}
                </Tabs>
            </div>
        </React.Fragment>
    )
}
export default CampaignsHeader
