import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Button, ToolBar } from '@components'
import paymentApi, { type PaymentMinimal } from '@payment/api'
import { usePaymentEvents } from '@payment/hooks'
import type { CaptureFooterProps } from './Capture.types'
import { useNotification } from '@core/hooks'

const CaptureFooter: React.FC<CaptureFooterProps> = ({
    payment,
    pointUsage,
    captureAvailable,
    onChangeStatus,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { alert, toast } = useNotification()

    const handlePaymentEvents = usePaymentEvents()

    const [completed, setCompleted] = React.useState<boolean>(false)

    const [respond, respondResult] = paymentApi.useRespondMutation()

    const navigateToWalletDetail = React.useCallback(() => {
        navigate('/', { replace: true })
    }, [navigate])

    const handleStatusComplete = React.useCallback(
        (paymentData: PaymentMinimal) => {
            navigateToWalletDetail()

            setTimeout(() => handlePaymentEvents(paymentData), 750)
        },
        [handlePaymentEvents, navigateToWalletDetail],
    )

    const handleStatusReject = React.useCallback(() => {
        toast({
            variant: 'default',
            icon: 'warning',
            message: t('@payment.Capture.status_reject_message'),
            duration: 5000,
        })

        navigateToWalletDetail()
    }, [navigateToWalletDetail, t, toast])

    const handleRespond = React.useCallback(
        async (reply: boolean) => {
            try {
                setCompleted(true)

                const result = await respond({
                    payment_id: payment.payment_id,
                    reply: reply,
                    point_usage: pointUsage,
                }).unwrap()

                if (result.data.status === 'reject') return handleStatusReject()

                if (result.data.status === 'complete')
                    return handleStatusComplete(result.data)

                onChangeStatus(result.data.status)
            } catch {
                navigateToWalletDetail()
            }
        },
        [
            handleStatusComplete,
            handleStatusReject,
            navigateToWalletDetail,
            onChangeStatus,
            payment,
            pointUsage,
            respond,
        ],
    )

    const showCancelAlert = React.useCallback(() => {
        alert({
            title: t('@payment.Capture.cancel_alert_title'),
            message: t('@payment.Capture.cancel_alert_message'),
            icon: 'warning',
            actions: [
                {
                    loading: true,
                    variant: 'primary',
                    children: t('@payment.Capture.cancel_alert_approve_button'),
                    onClick: () => handleRespond(false),
                },
                {
                    variant: 'secondary',
                    children: t('@payment.Capture.cancel_alert_cancel_button'),
                },
            ],
        })
    }, [alert, handleRespond, t])

    return (
        <ToolBar className="fixed bottom-0 left-0 w-full">
            <ToolBar.Left>
                <Button
                    style={{ justifyContent: 'flex-start' }}
                    variant="ghost-on-light"
                    size="small"
                    onClick={showCancelAlert}>
                    {t('@payment.Capture.action_button_cancel_title')}
                </Button>
            </ToolBar.Left>
            <ToolBar.Right className="w-full">
                <Button
                    className="w-full"
                    disabled={!captureAvailable}
                    loading={respondResult.isLoading || completed}
                    onClick={() => handleRespond(true)}>
                    {t('@payment.Capture.button_payment_title')}
                </Button>
            </ToolBar.Right>
        </ToolBar>
    )
}
export default CaptureFooter
