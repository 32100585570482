import * as React from 'react'
import { useLocation } from 'react-router'
import { filter, map } from 'lodash'
import { AccountActivityList } from '@wallet/components'
import type { FilterDateItem, FilterItem } from '@wallet/views'
import { convertArrayToObject } from '@wallet/views'
import type { AccountActivitiesLocationState } from './AccountActivities.types'
import AccountActivitiesHeader from './AccountActivitiesHeader'

const AccountActivities: React.FC = () => {
    const location: AccountActivitiesLocationState = useLocation().state
    const walletId =
        location?.walletId ?? localStorage.getItem('user.wallet_id')
    const [activeFilters, setActiveFilters] = React.useState<
        (FilterItem | FilterDateItem)[]
    >([])

    const config = React.useMemo(() => {
        const convertedFilter = convertArrayToObject(
            activeFilters as FilterItem[],
        )

        const balanceFilters = filter(
            activeFilters,
            filterItem =>
                filterItem.type !== 'started_date' &&
                filterItem.type !== 'end_date',
        ) as FilterItem[]

        const balanceFiltersTypes = map(balanceFilters, item => item.type)
        return {
            wallet_id: walletId ?? undefined,
            start_date: convertedFilter.started_date?.date ?? undefined,
            end_date: convertedFilter.end_date?.date ?? undefined,
            filters: balanceFiltersTypes
                ? { ...balanceFiltersTypes }
                : undefined,
        }
    }, [activeFilters, walletId])

    return (
        <div className="pb-10">
            <AccountActivitiesHeader
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
            />
            <AccountActivityList.SectionList config={config} />
        </div>
    )
}
export default AccountActivities
