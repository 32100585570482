import * as React from 'react'
import { every, first, size } from 'lodash'
import type { DefaultDepositAmount } from '@company/api'
import type { useCheckAmountProps } from './TopUp.types'
import { checkDepositAmount, getAmount } from './TopUp.utils'

export const useDefaultDepositAmount = (
    defaultDepositAmounts: DefaultDepositAmount[] | undefined = [],
) => {
    const [defaultAmount, setDefaultAmount] = React.useState<number>(0)

    React.useEffect(() => {
        if (!size(defaultDepositAmounts)) return
        const firstAmount = first(defaultDepositAmounts)
        firstAmount && setDefaultAmount(firstAmount.amount)
    }, [defaultDepositAmounts])

    return defaultAmount
}

export const useCheckAmount = ({
    location,
    wallet,
    setIsCustomAmount,
    type,
}: useCheckAmountProps) => {
    const [amount, setAmount] = React.useState<number>(0)

    const state = location?.trigger_data

    const checkAmount = React.useCallback(() => {
        if (!state?.amount || !wallet) return

        const isAmount = every(
            wallet?.company.settings.default_deposit_amounts,
            amountObj => amountObj.amount !== state.amount,
        )

        setIsCustomAmount(isAmount)

        setAmount(state.amount)
    }, [state, setIsCustomAmount, wallet])

    const checkPaymentAmount = React.useCallback(async () => {
        if (!state?.payment_amount) return

        const result = await getAmount(state.payment_amount)

        if (!result?.deposit_balance || !wallet) return

        const isAmount = checkDepositAmount(
            wallet.company.settings.default_deposit_amounts,
            result,
        )

        setIsCustomAmount(isAmount)

        if (result.point_usage.status)
            return setAmount(result.deposit_point_and_balance)

        return setAmount(result.deposit_balance)
    }, [state, setIsCustomAmount, wallet])

    React.useEffect(() => {
        type === 'payment' ? checkPaymentAmount() : checkAmount()
    }, [checkAmount, checkPaymentAmount, type])

    return amount
}
