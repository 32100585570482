import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Toast, type ToastType } from '@components'
import { useCompletePurchase, useTopUp } from '@deposit/hooks'
import { MasterPassOTP } from '@deposit/views'
import type { DepositToastProps } from './Capture.types'
import { emitter } from '@core/utils'

const DepositToast: React.FC<DepositToastProps> = ({
    wallet,
    payment,
    walletId,
    depositAmount,
    onComplete,
    ...props
}) => {
    const { t } = useTranslation()
    const handleCompletePurchase = useCompletePurchase()

    const [topUpSuccess, setTopUpSuccess] = React.useState<boolean>(false)

    const complete = React.useCallback(() => {
        onComplete()
        setTopUpSuccess(true)
    }, [onComplete])

    const topUp = useTopUp(
        {
            walletId: walletId,
            amount: depositAmount,
            installmentCount: null,
            redirect: {
                payment,
                wallet,
                path: '/capture',
            },
        },
        handleCompletePurchase,
    )

    const ToastRightComponent = React.useCallback(
        (type: ToastType) => {
            if (type !== 'error') return <React.Fragment />
            return (
                <Toast.Action
                    variant={type}
                    loading={topUp.loading}
                    children={t('@payment.Capture.toast_chip_label')}
                    onClick={topUp.init}
                />
            )
        },
        [t, topUp.init, topUp.loading],
    )

    React.useEffect(() => {
        emitter.on('topup-complete', complete)

        return () => {
            emitter.off('topup-complete', complete)
        }
    }, [complete])

    if (depositAmount === 0 || topUpSuccess) return null

    return (
        <div className="fixed bottom-[calc(97px+12px)] left-0 w-full px-3">
            <Toast
                as="div"
                variant="error"
                icon="warning"
                message={t('@payment.Capture.toast_error_message')}
                LayoutRightComponent={ToastRightComponent}
                {...props}
            />
            <MasterPassOTP />
        </div>
    )
}
export default DepositToast
