import type { RouteObject } from 'react-router'
import { MasterPass3D, Payment3D, TopUp } from '@deposit/views'

export const DepositRouter: RouteObject[] = [
    {
        path: '/topup',
        element: <TopUp />,
    },
    {
        path: '/masterpass-3d',
        element: <MasterPass3D />,
    },
    {
        path: '/payment-3d',
        element: <Payment3D />,
    },
]
