import * as React from 'react'
import Skeleton from '../Skeleton/Skeleton'

const WalletCardPayingLoader: React.FC = () => {
    return (
        <div className="container pt-10">
            <div className="space-y-4">
                <Skeleton className="h-3 w-14 rounded-md" />
                <Skeleton className="h-[104px] w-full rounded-[20px]" />
                <div className="flex items-center justify-between">
                    <Skeleton className="h-4 w-32 rounded-md" />
                    <Skeleton className="h-8 w-[52px] rounded-full" />
                </div>
            </div>
        </div>
    )
}
export default WalletCardPayingLoader
