import * as React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Button, ToolBar } from '@components'
import companyApi from '@company/api'
import type { WalletContractLocationState } from './WalletContract.types'
import { useNotification } from '@core/hooks'
import WalletContractDescription from './WalletContractDescription'
import WalletContractHeader from './WalletContractHeader'
import './WalletContract.css'

const WalletContract: React.FC = () => {
    const navigate = useNavigate()
    const location: WalletContractLocationState = useLocation()
    const { t } = useTranslation()
    const { toast } = useNotification()

    const [walletSubscribe, walletSubscribeResult] =
        companyApi.useWalletSubscribeMutation()

    const detailsQuery = companyApi.useDetailsQuery({
        company_id: location.state.company_id,
    })

    const details = React.useMemo(() => {
        return detailsQuery.data?.data
    }, [detailsQuery.data])

    const handleWalletSubscribe = React.useCallback(async () => {
        const result = await walletSubscribe({
            company_id: location.state.company_id,
            agreement_approved: true,
        }).unwrap()

        localStorage.setItem('user.wallet_id', String(result.data.wallet.id))

        if (location.state.redirect_page === 'deposit')
            return navigate('/topup', {
                state: {
                    wallet_id: result.data.wallet.id,
                    trigger_data: location.state?.trigger_data,
                },
            })

        navigate('/', {
            state: {
                walletId: result.data.wallet.id,
                wallet: result.data.wallet,
            },
        })

        toast({
            variant: 'success',
            duration: 5000,
            message: t('@guest.CompanyWalletSubscribe.success_message'),
        })
    }, [
        location.state.company_id,
        location.state.redirect_page,
        location.state?.trigger_data,
        navigate,
        t,
        toast,
        walletSubscribe,
    ])

    if (!details) return null

    return (
        <div className="wallet-contract-content">
            <WalletContractHeader title={details.name} />
            <WalletContractDescription description={details.agreement.html} />
            <ToolBar className="sticky bottom-0 left-0 w-full">
                <ToolBar.Left>
                    <p className="w-[148px] text-body-md-r text-semantic-content-inkMedium">
                        {t('@guest.CompanyWalletSubscribe.agreement_message')}
                    </p>
                </ToolBar.Left>
                <ToolBar.Right>
                    <Button
                        children={t(
                            '@guest.CompanyWalletSubscribe.add_button_title',
                        )}
                        loading={walletSubscribeResult.isLoading}
                        onClick={handleWalletSubscribe}
                    />
                </ToolBar.Right>
            </ToolBar>
        </div>
    )
}
export default WalletContract
