import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Formik, type FormikProps } from 'formik'
import { Button, Input, Toast } from '@components'
import { goldFramerApi } from '@deposit/api'
import { useCompletePurchase } from '@deposit/hooks'
import type {
    GoldFramerPaymentFormProps,
    PaymentFormProps,
} from './PaymentFrom.types.ts'
import { MaskUtils } from '@core/utils'
import { paymentFormValidations } from './PaymentForm.validations'

const GoldFramerPaymentForm: React.FC<GoldFramerPaymentFormProps> = ({
    walletId,
    amount,
    companyId,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleCompletePurchase = useCompletePurchase(amount)

    const formikRef = React.useRef<FormikProps<PaymentFormProps>>(null)

    const [bin, setBin] = React.useState<string | null>(null)

    const [payCheckApply, payCheckResult] = goldFramerApi.usePayCheckMutation()
    const [payApply, payResult] = goldFramerApi.usePayMutation()

    const payCheck = React.useMemo(
        () => payCheckResult.data,
        [payCheckResult.data],
    )

    const isPayCheckValid = React.useMemo(
        () =>
            !!payCheck?.will_convert && !!bin && bin.length === 6 && amount > 0,
        [amount, bin, payCheck],
    )

    const handleSubmit = React.useCallback(
        async (values: PaymentFormProps) => {
            const CardNo = MaskUtils.getRawValue(
                'credit-card',
                values.creditCardNo,
            )

            const expireMonth = values.expireDate.slice(0, 2)
            const expireYear = '20' + values.expireDate.slice(3, 5)

            const result = await payApply({
                amount: amount,
                wallet_id: walletId,
                card: {
                    no: CardNo,
                    expiry_month: expireMonth,
                    expiry_year: expireYear,
                    cvc: values.cvcNo,
                },
            }).unwrap()

            if (result.is_3d)
                return navigate('/payment-3d', {
                    state: {
                        threeDUrl: result.iframe_url,
                    },
                })

            handleCompletePurchase(true, 'masterpass')
        },
        [amount, handleCompletePurchase, navigate, payApply, walletId],
    )

    React.useEffect(() => {
        if (!bin) return

        if (bin.length === 6 && amount > 0) {
            payCheckApply({
                amount: amount,
                card: { no: bin },
            })
        }
    }, [bin, amount, payCheckApply])

    if (companyId !== 222) return null

    return (
        <React.Fragment>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    amount: amount,
                    creditCardNo: '',
                    expireDate: '',
                    cvcNo: '',
                }}
                onSubmit={handleSubmit}
                validationSchema={paymentFormValidations}>
                {formik => (
                    <div>
                        <div className="mb-8 mt-10 space-y-4">
                            <Input
                                label={t(
                                    '@deposit.TopUpAnotherCard.form_label_credit_card_no',
                                )}
                                error={
                                    formik.touched.creditCardNo &&
                                    !!formik.errors.creditCardNo
                                }
                                helperText={
                                    formik.touched.creditCardNo
                                        ? formik.errors.creditCardNo
                                        : undefined
                                }
                                maskType="credit-card"
                                autoComplete="cc-number"
                                placeholder="•••• •••• •••• ••••"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                value={formik.values.creditCardNo}
                                onChange={e => {
                                    const value = e.target.value.replace(
                                        /\D/g,
                                        '',
                                    )

                                    formik.handleChange('creditCardNo')(e)

                                    setBin(value.slice(0, 6))
                                }}
                            />
                            <div className="grid grid-cols-2 gap-6">
                                <Input
                                    label={t(
                                        '@deposit.TopUpAnotherCard.form_label_expire_date',
                                    )}
                                    error={
                                        formik.touched.expireDate &&
                                        !!formik.errors.expireDate
                                    }
                                    helperText={
                                        formik.touched.expireDate
                                            ? formik.errors.expireDate
                                            : undefined
                                    }
                                    maskType="date-time"
                                    maskOptions={{
                                        dateTimePattern: 'MM/YY',
                                    }}
                                    autoComplete="cc-exp"
                                    placeholder={t(
                                        '@deposit.TopUpAnotherCard.form_placeholder_expire_date',
                                    )}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    value={formik.values.expireDate}
                                    onChange={formik.handleChange('expireDate')}
                                />
                                <Input
                                    label={t(
                                        '@deposit.TopUpAnotherCard.form_label_cvc_no',
                                    )}
                                    error={
                                        formik.touched.cvcNo &&
                                        !!formik.errors.cvcNo
                                    }
                                    helperText={
                                        formik.touched.cvcNo
                                            ? formik.errors.cvcNo
                                            : undefined
                                    }
                                    autoComplete="cc-csc"
                                    placeholder={t(
                                        '@deposit.TopUpAnotherCard.form_placeholder_cvc_no',
                                    )}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    value={formik.values.cvcNo}
                                    onChange={formik.handleChange('cvcNo')}
                                />
                            </div>
                        </div>
                        {isPayCheckValid && payCheck && (
                            <Toast
                                as="div"
                                variant="suggestion"
                                icon="info"
                                message={t(
                                    '@deposit.GoldFramer.warning_message',
                                    {
                                        amount: payCheck.amount,
                                    },
                                )}
                                className="mt-8"
                            />
                        )}

                        <Button
                            type="submit"
                            className="mt-8 w-full"
                            disabled={!formik.isValid || amount === 0}
                            loading={payResult.isLoading}
                            onClick={() => formik.handleSubmit()}>
                            {t('@deposit.TopUp.footer_submit_button')}
                        </Button>
                    </div>
                )}
            </Formik>
        </React.Fragment>
    )
}
export default GoldFramerPaymentForm
