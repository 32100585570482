import * as React from 'react'
import { useTranslation } from 'react-i18next'
import type { FooterProps } from './Footer.types.ts'
// import { map } from 'lodash'
// import { Button } from '@components'
// import { FooterMenuItems } from './Footer.constants'
import './Footer.css'

const Footer: React.FC<FooterProps> = ({ isHidden }) => {
    const { t } = useTranslation()

    if (isHidden) return null

    return (
        <footer className="footer animate-enter">
            <div className="footer-menu">
                {/*{map(FooterMenuItems, (item, index) => (*/}
                {/*    <Button*/}
                {/*        key={index}*/}
                {/*        variant="link"*/}
                {/*        size="tiny"*/}
                {/*        className="w-fit"*/}
                {/*        children={t(item.title)}*/}
                {/*    />*/}
                {/*))}*/}
            </div>
            <p className="footer-copyright">
                {t('content_config.Footer.copyright')}
            </p>
        </footer>
    )
}

export default Footer
