import * as React from 'react'
import { useNavigate, useSearchParams } from 'react-router'
import baseApi from '@core/api'
import { DayJSMiddlewares, ThemeMiddlewares } from '@core/middlewares'
import { setPlatform } from '@core/slices/BaseSlice.ts'
import { useAppDispatch } from '@core/store'
import i18n from '@core/translations'
import { Splash } from '@guest/views'
import { setLocalStorage } from './Initialize.utils'

const Initialize: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams()

    const token = searchParams.get('token') || ''

    const [isNavigate, setIsNavigate] = React.useState<boolean>(false)

    const InitializeQuery = baseApi.useInitializeQuery({ token })

    const initialize = React.useMemo(
        () => InitializeQuery.data?.data,
        [InitializeQuery.data],
    )

    const error = React.useMemo(
        () => InitializeQuery?.error,
        [InitializeQuery.error],
    )

    const InitializeApp = React.useCallback(async () => {
        localStorage.clear()

        if (!token) return navigate('/404', { replace: true })

        if (error) {
            if ('status' in error)
                return navigate('/404', {
                    replace: true,
                    state: {
                        data: error.status !== 404 && error.data,
                    },
                })
        }

        if (!initialize) return

        i18n.changeLanguage(initialize.auth_data.locale)
        setLocalStorage(token, initialize)

        dispatch(setPlatform(initialize.platform))

        DayJSMiddlewares.setLocale()
        ThemeMiddlewares.SetTheme(initialize.company.color)

        await dispatch(baseApi.endpoints.me.initiate()).unwrap()
        setIsNavigate(true)
    }, [dispatch, error, initialize, navigate, token])

    React.useEffect(() => {
        InitializeApp()
    }, [InitializeApp])

    return <Splash initialize={initialize} isNavigate={isNavigate} />
}
export default Initialize
