import * as React from 'react'
import { Navigate, Outlet } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@core/store'
import { Background } from '@guest/components'
import { NotificationProvider } from '@core/hooks'
import '../Layout.css'

const Guest: React.FC = () => {
    const { t } = useTranslation()
    const company = useAppSelector(state => state.baseSlice.company)
    const token = localStorage.getItem('user.access_token')

    if (token) return <Navigate to="/" replace />

    return (
        <NotificationProvider>
            <div className="guest-container">
                <Background color={company.color} />
                <div className="guest-wrapper">
                    <img
                        src={company.logo}
                        alt={company.name}
                        className="max-h-12 object-contain"
                    />
                    <div className="guest-content">
                        <Outlet />
                    </div>
                </div>
                <p className="guest-copyright">
                    {t('content_config.Footer.copyright')}
                </p>
            </div>
        </NotificationProvider>
    )
}
export default Guest
