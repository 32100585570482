import type { RouteObject } from 'react-router'
import { AccountActivities, WalletDetail } from '@wallet/views'

export const WalletRouter: RouteObject[] = [
    {
        path: '/',
        element: <WalletDetail />,
    },
    {
        path: '/account-activities',
        element: <AccountActivities />,
    },
]
