import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { Badge, Carousel, IconButton, Input, SectionBar } from '@components'
import { AccountActivitiesFilter } from '@wallet/views'
import { convertArrayToObject } from '@wallet/views'
import type { AccountActivitiesHeaderProps } from './AccountActivities.types'
import { useTheme } from '@core/utils'

const AccountActivitiesHeader: React.FC<AccountActivitiesHeaderProps> = ({
    activeFilters,
    setActiveFilters,
}) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const triggerRef = React.useRef<HTMLButtonElement>(null)

    const convertedFilter = React.useMemo(
        () => convertArrayToObject(activeFilters),
        [activeFilters],
    )

    return (
        <React.Fragment>
            <SectionBar
                title={t('@wallet.AccountActivities.content_header_title')}
                description={t(
                    '@wallet.AccountActivities.content_header_description',
                )}
                LayoutBackButton={{
                    onClick: () => navigate(-1),
                }}
                className="container sticky top-0 z-50 bg-semantic-content-inkInverse py-7 lg:static lg:z-auto"
            />
            <div className="border-b border-semantic-background-tertiary pb-10">
                <div className="container space-y-10">
                    <div className="space-y-6">
                        <Input
                            labelClassName="!pr-1"
                            id="input"
                            leftIcon="search"
                            type="texts"
                            placeholder="Ara"
                            disabled={true}
                            LayoutRightComponent={
                                <AccountActivitiesFilter
                                    ref={triggerRef}
                                    activeFilters={activeFilters}
                                    setActiveFilters={setActiveFilters}
                                    startedDate={
                                        convertedFilter.started_date?.date ??
                                        undefined
                                    }
                                    endDate={
                                        convertedFilter.end_date?.date ??
                                        undefined
                                    }
                                />
                            }
                        />
                        {activeFilters.length > 0 ? (
                            <Carousel opts={{ dragFree: true }}>
                                <Carousel.Content className="-ml-2 w-[calc(100vw-48px)] lg:w-[calc(100vw-360px)] 2xl:w-auto">
                                    <Carousel.Item className="basis-auto pl-2">
                                        <IconButton
                                            key="filter"
                                            size="small"
                                            color={
                                                theme.colors.semantic.content
                                                    .inkInverse
                                            }
                                            name="control"
                                            onClick={() =>
                                                triggerRef.current?.click()
                                            }
                                        />
                                    </Carousel.Item>
                                    {map(activeFilters, (filter, key) => (
                                        <Carousel.Item
                                            key={key}
                                            className="basis-auto pl-2">
                                            <Badge
                                                size="medium"
                                                variant="brand-tonal"
                                                leftIcon="close-circle"
                                                className="flex-row-reverse"
                                                children={
                                                    'date' in filter &&
                                                    filter.date !== undefined
                                                        ? filter.date
                                                        : 'labelType' in
                                                              filter &&
                                                          t(
                                                              `@wallet.AccountActivitiesFilter.${filter.labelType}`,
                                                          )
                                                }
                                                onClick={() => {
                                                    const editedFilter =
                                                        activeFilters.filter(
                                                            filterItem =>
                                                                filterItem.type !==
                                                                filter.type,
                                                        )
                                                    setActiveFilters(
                                                        editedFilter,
                                                    )
                                                }}
                                            />
                                        </Carousel.Item>
                                    ))}
                                </Carousel.Content>
                            </Carousel>
                        ) : null}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default AccountActivitiesHeader
