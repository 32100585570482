import * as React from 'react'
import type {
    SectionBarProps,
    SectionBarStaticProperties,
} from './SectionBar.types'
import { RenderLayoutRightComponent } from './SectionBar.utils'
import { cn } from '@core/utils'
import IconButton from '../IconButton/IconButton'
import SectionBarLoader from './SectionBarLoader'

const SectionBar: React.FC<SectionBarProps> & SectionBarStaticProperties = ({
    title,
    description,
    LayoutBackButton,
    LayoutRightComponent,
    LayoutRightComponentProps,
    MobileRightComponentProps,
    NavigateLink,
    className,
    ...props
}) => {
    return (
        <div className={cn('space-y-2', className)} {...props}>
            <div className="flex items-center justify-between gap-2">
                <div className="flex items-center gap-4">
                    {LayoutBackButton && (
                        <IconButton
                            name="arrow-left"
                            size="medium"
                            {...LayoutBackButton}
                        />
                    )}
                    <h2 className="animate-enter line-clamp-1 text-display-lg-b text-semantic-content-ink">
                        {title}
                    </h2>
                </div>
                <RenderLayoutRightComponent
                    LayoutRightComponent={LayoutRightComponent}
                    LayoutRightComponentProps={LayoutRightComponentProps}
                    MobileRightComponentProps={MobileRightComponentProps}
                    NavigateLink={NavigateLink}
                />
            </div>
            {description && (
                <p className="animate-enter text-body-md-r text-semantic-content-inkLight">
                    {description}
                </p>
            )}
        </div>
    )
}

SectionBar.Loader = SectionBarLoader

export default SectionBar
