import * as React from 'react'
import type { RedirectProps } from '@core/context'
import type { CardType } from '@deposit/api'
import { emitter } from '@core/utils'

const UseCompletePurchase = (amount?: number) => {
    return React.useCallback(
        (
            success: boolean,
            paymentMethod: CardType,
            redirect?: RedirectProps,
        ) => {
            const walletId = Number(localStorage.getItem('user.wallet_id'))

            if (!success) {
                return emitter.emit('topup-failed', {
                    walletId,
                    amount,
                    payment_method: paymentMethod,
                    redirect,
                })
            }

            emitter.emit('topup-complete', {
                walletId,
                amount,
                payment_method: paymentMethod,
                redirect,
            })
        },
        [amount],
    )
}
export default UseCompletePurchase
