import * as React from 'react'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Carousel, SectionBar } from '@components'
import campaignApi from '@campaign/api'
import walletApi from '@wallet/api'
import type { CampaignRewardListProps } from './CampaignRewardList.types'
import CampaignRewardListLoader from './CampaignRewardListLoader'
import RewardTopUpCharts from './RewardTopUpCharts'
import RewardWonsChart from './RewardWonsChart'

const CampaignRewardList: React.FC<CampaignRewardListProps> = ({
    walletId,
    companyId,
    currency,
    color,
}) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const lastEarnedDepositGiftPointsQuery =
        walletApi.useWalletLastEarnedDepositGiftPointsQuery(
            { wallet_id: walletId },
            { refetchOnMountOrArgChange: true },
        )

    const lastEarnedDepositGiftPointsData = React.useMemo(() => {
        return lastEarnedDepositGiftPointsQuery.data?.data
    }, [lastEarnedDepositGiftPointsQuery.data])

    const pointWonCampaignQuery = walletApi.useWalletPointWonCampaignQuery(
        { wallet_id: walletId },
        { refetchOnMountOrArgChange: true },
    )

    const pointWonCampaignData = React.useMemo(() => {
        return pointWonCampaignQuery.data?.data ?? []
    }, [pointWonCampaignQuery.data?.data])

    const campaignNewsListQuery = campaignApi.useCampaignNewsListQuery(
        { page: 1, company_id: companyId, campaign_types: undefined },
        { refetchOnMountOrArgChange: true },
    )

    const campaignNewsListData = React.useMemo(() => {
        return campaignNewsListQuery.data?.data ?? []
    }, [campaignNewsListQuery.data?.data])

    if (
        lastEarnedDepositGiftPointsQuery.isLoading ||
        pointWonCampaignQuery.isLoading ||
        campaignNewsListQuery.isLoading
    )
        return <CampaignRewardListLoader />

    if (
        !lastEarnedDepositGiftPointsData &&
        !pointWonCampaignData.length &&
        !campaignNewsListData.length
    )
        return null

    return (
        <div className="space-y-8">
            <SectionBar
                title={t('@campaign.CampaignRewardList.campaign_section_title')}
                description={t(
                    '@campaign.CampaignRewardList.campaign_section_description',
                )}
                LayoutRightComponent
                NavigateLink={() => navigate('/campaign')}
                LayoutRightComponentProps={{
                    children: t(
                        '@campaign.CampaignRewardList.campaigns_chips_label_all',
                    ),
                }}
            />
            <Carousel opts={{ dragFree: true }}>
                <Carousel.Content className="hover:cursor-grab active:cursor-grabbing">
                    <RewardWonsChart
                        data={pointWonCampaignData}
                        color={color}
                    />
                    <RewardTopUpCharts
                        data={lastEarnedDepositGiftPointsData}
                        color={color}
                        currency={currency}
                    />
                </Carousel.Content>
            </Carousel>
        </div>
    )
}

export default CampaignRewardList
