import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { type FormikHelpers, useFormik } from 'formik'
import { CurrencyFormatter } from '@macellan/formatter'
import { Button, Input, ModuleItem, Sheet } from '@components'
import { useAppSelector } from '@core/store'
import i18n from '@core/translations'
import walletApi from '@wallet/api'
import type { CouponApplyForm, CouponApplyProps } from './CouponApply.types'
import { useNotification, useWindowSize } from '@core/hooks'
import { couponApplyValidation } from './CouponApply.validations'

const CouponApply: React.FC<CouponApplyProps> = ({ currency, walletId }) => {
    const { t } = useTranslation()
    const { toast } = useNotification()
    const ScreenWidth = useWindowSize().width

    const company = useAppSelector(state => state.baseSlice.company)

    const [open, setOpen] = React.useState<boolean>(false)

    const [pointCouponApply, pointCouponApplyResult] =
        walletApi.usePointCouponApplyMutation()

    const handleSubmit = React.useCallback(
        async (
            values: CouponApplyForm,
            formikHelpers: FormikHelpers<CouponApplyForm>,
        ) => {
            const result = await pointCouponApply({
                wallet_id: walletId,
                code: values.code,
            }).unwrap()

            const message = result.data.message

            const formattedAmount = CurrencyFormatter.format(
                result.data.amount,
                i18n.getLocale(),
                currency,
            )

            toast({
                icon: 'check',
                message: message
                    ? message
                    : t('@wallet.CouponApply.coupon_toast_message', {
                          amount: formattedAmount,
                      }),
                duration: 2000,
                variant: 'success',
            })

            setOpen(false)
            formikHelpers.resetForm()
        },
        [pointCouponApply, walletId, currency, toast, t],
    )

    const couponApplyFormik = useFormik({
        initialValues: {
            code: '',
        },
        onSubmit: (values, formikHelpers) =>
            handleSubmit(values, formikHelpers),
        validationSchema: couponApplyValidation,
    })

    return (
        <Sheet open={open} onOpenChange={setOpen}>
            <Sheet.Trigger asChild>
                <React.Fragment>
                    {ScreenWidth > 1024 && (
                        <Button
                            variant="ink-inverse"
                            leftIcon="coupon"
                            className="w-full gap-4 pl-4 pr-6 text-label-md lg:w-fit"
                            disabled={company.is_wallet_frozen}
                            onClick={() => setOpen(true)}>
                            {t('@wallet.CouponApply.coupon_add_button')}
                        </Button>
                    )}
                    {ScreenWidth < 1024 && (
                        <ModuleItem.Button
                            children={t(
                                '@wallet.CouponApply.coupon_add_button',
                            )}
                            variant="secondary"
                            icon="coupon"
                            disabled={company.is_wallet_frozen}
                            onClick={() => setOpen(true)}
                        />
                    )}
                </React.Fragment>
            </Sheet.Trigger>
            <Sheet.Content>
                <Sheet.Header>
                    <Sheet.Title>
                        {t('@wallet.CouponApply.route_header_title')}
                    </Sheet.Title>
                    <Sheet.Description>
                        {t('@wallet.CouponApply.content_header_description')}
                    </Sheet.Description>
                </Sheet.Header>
                <div className="space-y-6">
                    <form onSubmit={couponApplyFormik.handleSubmit}>
                        <Input
                            type="text"
                            placeholder={t(
                                '@wallet.CouponApply.coupon_input_placeholder',
                            )}
                            autoCapitalize="characters"
                            autoFocus={true}
                            value={couponApplyFormik.values.code.toUpperCase()}
                            onChange={couponApplyFormik.handleChange('code')}
                            onBlur={couponApplyFormik.handleBlur('code')}
                            error={!!couponApplyFormik.errors.code}
                            helperText={couponApplyFormik.errors.code}
                        />
                        <Button
                            type="submit"
                            variant="primary"
                            className="mt-4 w-full"
                            children={t(
                                '@wallet.CouponApply.coupon_add_button',
                            )}
                            disabled={!couponApplyFormik.isValid}
                            loading={pointCouponApplyResult.isLoading}
                        />
                    </form>
                </div>
            </Sheet.Content>
        </Sheet>
    )
}

export default CouponApply
