import React from 'react'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import depositApi from '@deposit/api'
import { useCompletePurchase } from '@deposit/hooks'
import { useNotification } from '@core/hooks'

const Payment3D: React.FC = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const { toast } = useNotification()

    const handleCompletePurchase = useCompletePurchase()

    const completed = React.useRef<boolean>(false)

    const [mark3DClosed] = depositApi.useMark3DClosedMutation()

    const handleMessage = (event: MessageEvent) => {
        completed.current = true

        let success: boolean = false

        const result = JSON.parse(event.data.message)

        if (result.status === 1) {
            success = true
        }

        if (result.status === 0) {
            success = false

            const errorMessage = result.message?.length
                ? result.message
                : t('@deposit.MasterPass3D.error_message')
            toast({
                variant: 'error',
                message: errorMessage,
                icon: 'warning',
                duration: 5000,
            })
        }

        return handleCompletePurchase(
            success,
            'masterpass',
            location.state?.redirect,
        )
    }

    React.useEffect(
        () => {
            window.addEventListener('message', handleMessage)

            return () => {
                window.removeEventListener('message', handleMessage)

                if (completed) return
                mark3DClosed({
                    deposit_id: location.state?.depositId,
                })
            }
        },
        // eslint-disable-next-line
        [],
    )

    return (
        <React.Fragment>
            <iframe
                src={location.state.threeDUrl}
                className="min-h-screen w-full"
                loading="lazy"
                allowFullScreen={true}
            />
        </React.Fragment>
    )
}

export default Payment3D
